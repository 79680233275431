.page {
    height: 90vh;
    width: 70vh;
    min-height: 800px;
    min-width: 700px;
    position: absolute;
    top: 7%;
    left: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

}

.title {
    color: white;
    font-family: "Times New Roman";
    font-size: 100pt;
    letter-spacing: 10px;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    filter: drop-shadow(-5px 5px 5px black);
}

.author {
    color: white;
    white-space: nowrap;
    font-family: "Times New Roman";
    font-size: 25pt;
    letter-spacing: 5px;
    position: absolute;
    left: 50%;
    top: 25%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.start {
    font-size: 30pt;
    position: absolute;
    left: 50%;
    top: 40%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 110px;
    width: 220px;
    filter: drop-shadow(0px 5px 5px black);
    box-shadow: 0px 4px 4px black;
}