.page2 {
    height: 80vh;
    width: 32vw;
    min-height: 700px;
    min-width: 600px;
    position: relative;
    top: -400vh;
    background-color: white;
    margin: auto;
}

.layers {
    position: absolute;
    height: 80vh;
    width: 32vw;
    min-height: 700px;
    min-width: 600px;
    top: 74px;
    left: 49.5%;
    background-color: #56435a;
    opacity: 0;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.rewrite {
    font-family: "Times New Roman" !important;
    position: relative !important;
    left: 50%;
    top: 90px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 50px;
    width: 100px;
    filter: drop-shadow(0px 5px 5px black);
    box-shadow: 0px 4px 4px black;
}

.contents {
    width: 400px;
    position: absolute;
    top: 2vh;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: white;
    opacity: 100%;
}

.contents-first {
    width: 400px;
    position: absolute;
    top: 2vh;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: white;
    opacity: 0%;
}

.contents-second {
    width: 400px;
    position: absolute;
    top: 2vh;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0%;
}

.contents-third {
    width: 400px;
    position: absolute;
    top: 2vh;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    opacity: 0%;
}

.contents-fourth {
    width: 400px;
    position: absolute;
    top: 2vh;
    left: 50%;
    top: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: 0%;
}

p, h2 {
    font-family: "Times New Roman";
}

p {
    font-size: 12.5pt;
    min-width: fit-content;
    width: 400px;
    margin: 0;
    padding: 4px;
}

.text-layers-1, .text-layers-2{
    padding: 4px;
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) translateY(-100%);
    opacity: 0;
}

.text-layers-1{
    color: #9d86b2;
    left: 50.5%;
}

.text-layers-2{
    color: #506680;
    left: 51%;
}

#stanza {
    padding-bottom: 30px;
}

.stanza5 {
    padding-bottom: 0 !important;
}

.stanza-unique{
    width: fit-content;
    margin: auto;
    font-size: 12.5pt;
    font-family: "Times New Roman";
}

.text-animation {
    position: relative;
    font-size: 12.5pt;
    font-family: "Times New Roman";
}

.text-animation, .letter{
    color: black;
    display: inline-block;
    opacity: 1;
}

.cursor {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 23px;
    width: 3px;
    background: black;
    z-index: 1;
}

path{
    stroke-dashoffset: 0;
}

#take-cross-svg{
     position: absolute;
     top: 11.5%;
     left: 5%;
     -ms-transform: translateX(-50%);
     transform: translateX(-50%) scale(.08);
     overflow: visible;
     stroke-dashoffset: 0;
 }

.take-cross {
    stroke: black;
    stroke-width: 34;
    fill: none;
    filter: url(#filter);
}

#break-svg {
    position: absolute;
    top: -6.5%;
    left: 5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.08);
    overflow: visible;
    stroke-dashoffset: 0;
}

#break-svg > path {
    stroke: black;
    stroke-width: 10;
    fill: none;
    filter: url(#filter);
}

#benches-cross-svg{
    position: absolute;
    top: 12%;
    left: 55%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.3);
    overflow: visible;
    stroke-dashoffset: 0;
}

.benches-cross {
    stroke: black;
    stroke-width: 13;
    fill: none;
    filter: url(#filter);
}

#mulch-svg {
    position: absolute;
    top: 4%;
    left: 55%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.25);
    overflow: visible;
    stroke-dashoffset: 0;
}

#mulch-svg > path {
    stroke: black;
    stroke-width: 15;
    fill: none;
    filter: url(#filter);
}

#as-scribble-svg{
    position: absolute;
    top: 0%;
    left: 43%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.075);
    overflow: visible;
    stroke-dashoffset: 0;
}

.as-scribble {
    stroke: black;
    stroke-width: 3;
    fill: none;
    filter: url(#filter);
}

#used-scribble-svg{
    position: absolute;
    top: 3.5%;
    left: 51.25%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.1);
    overflow: visible;
    stroke-dashoffset: 0;
}

.used-scribble {
    stroke: black;
    stroke-width: 4;
    fill: none;
    filter: url(#filter);
}

#decay-svg {
    position: absolute;
    top: 11%;
    left: 67%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.35);
    overflow: visible;
    stroke-dashoffset: 0;
}

#decay-svg > path {
    stroke: black;
    stroke-width: 13;
    fill: none;
    filter: url(#filter);
}

#face-scribble-svg{
    position: absolute;
    top: 28.5%;
    left: 65%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.08);
    overflow: visible;
    stroke-dashoffset: 0;
}

.face-scribble {
    stroke: black;
    stroke-width: 6;
    fill: none;
    filter: url(#filter);
}

#blood-svg {
    position: absolute;
    top: 30%;
    left: 70%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.35);
    overflow: visible;
    stroke-dashoffset: 0;
}

#blood-svg > path {
    stroke: black;
    stroke-width: 13;
    fill: none;
    filter: url(#filter);
}

#blood-scribble-svg{
    position: absolute;
    top: 26%;
    left: 66%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.15);
    overflow: visible;
    stroke-dashoffset: 0;
}

.blood-scribble {
    stroke: black;
    stroke-width: 6;
    fill: none;
    filter: url(#filter);
}

#turpentine-svg {
    position: absolute;
    top: 28%;
    left: 75%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.6);
    overflow: visible;
    stroke-dashoffset: 0;
}

#turpentine-svg > path {
    stroke: black;
    stroke-width: 11;
    fill: none;
    filter: url(#filter);
}

#peelitsskin-svg {
    position: absolute;
    top: 33%;
    left: -10%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.15);
    overflow: visible;
    stroke-dashoffset: 0;
}

#peelitsskin-svg > path {
    stroke: black;
    stroke-width: 7;
    fill: none;
}

#peelitsskin-scribble-svg{
    position: absolute;
    top: 25%;
    left: -15%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.075);
    overflow: visible;
    stroke-dashoffset: 0;
}

.peelitsskin-scribble {
    stroke: black;
    stroke-width: 2.5;
    fill: none;
    filter: url(#filter);
}


#circle-line10-svg{
    position: absolute;
    top: 49%;
    left: 12%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.3);
    overflow: visible;
    stroke-dashoffset: 0;
}

.circle-line10 {
    stroke: black;
    stroke-width: 4;
    fill: none;
    filter: url(#filter);
}

.cross-out-poem-svg{
    position: absolute;
    overflow: visible;
    opacity: 0;
    stroke-dashoffset: 0;
}

.cross-out-poem-svg > path {
    stroke: black;
    stroke-width: 3;
    fill: none;
    filter: url(#filter);
}

#cop-1{
    left: 33%;
    top: 12.5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.95);
}

#cop-2{
    left: 30%;
    top: 16.5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.9);
}

#cop-3{
    left: 33%;
    top: 20.75%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.15);
}

#cop-4{
    left: 10%;
    top: 33.75%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.1);
}

#cop-5{
    left: 48%;
    top: 38%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.8);
}

#cop-5 > path {
    stroke-width: 2.5;
}

#cop-6{
    left: 50%;
    top: 42.25%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.9);
}

#cop-7{
    left: 20%;
    top: 55%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.1);
}

#cop-8{
    left: 20%;
    top: 59%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1);
}

#cop-9{
    left: 20%;
    top: 63.25%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.9);
}

#cop-10{
    left: 40%;
    top: 85%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(1.4);
}

#cop-11{
    left: 30%;
    top: 89.5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.95);
}

#cop-12{
    left: 5%;
    top: 93.5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.75);
}

#cop-13{
    left: 5%;
    top: 97.5%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%) scale(.65);
}

