html {
  background-color: #28221e;
  min-width: 600px;
  min-height: 700px;
}

.MuiToolbar-root {
  filter: drop-shadow(0px 10px 10px black);
  box-shadow: 0px 4px 7px black;
}

.MuiTypography-root, .MuiButton-root{
  font-family: "Times New Roman" !important;
}

